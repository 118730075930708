<div class="title">Login</div>
<div class="formContainer">
    <form [formGroup]="loginForm" (submit)="login()">
        <mat-form-field>
            <mat-label>Username</mat-label>
            <input matInput formControlName="username" type="text">
        </mat-form-field>
        <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput formControlName="password" type="password">
        </mat-form-field>

        <button class="button" mat-raised-button type="submit" [disabled]="!loginForm.valid">Connect</button>
    </form>
</div>