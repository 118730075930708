import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { Language } from 'src/app/models/Language';
import { ApiService } from 'src/app/services/API/api.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {

  constructor(private router:Router, public language:LanguageService, private API:ApiService) { }

  startFR(){
    this.language.preferred=Language.FRENCH;
    this.start();
  }

  startEN(){
    this.language.preferred=Language.ENGLISH;
    this.start();
  }

  start(){
    this.router.navigate(['/','profile']);
  }

  login(){
    this.router.navigate(['/','db-access']);
  }

  ngOnInit(): void {
  }

}
