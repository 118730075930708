import { Component, OnInit } from '@angular/core';
import { Color } from 'src/app/models/Color';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  green:string = Color.GREEN;
  red:string = Color.RED;
  blue:string = Color.BLUE;

  constructor() { }

  ngOnInit(): void {
  }

}
