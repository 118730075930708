import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { DataFRService } from 'src/app/services/data/data-fr.service';
import { DataENService } from 'src/app/services/data/data-en.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Card } from 'src/app/models/Card';
import { Type } from 'src/app/models/Type';
import { Language } from 'src/app/models/Language';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ConditionnalcardsDialogComponent } from '../dialog/conditionnalcards-dialog/conditionnalcards-dialog.component';

@Component({
  selector: 'app-selection',
  templateUrl: './selection.component.html',
  styleUrls: ['./selection.component.css']
})
export class SelectionComponent implements OnInit {

  public data;

  MESSAGE_TIME:number = 5000;

  STRONG_WEAK_DIFF:number=2;
  MIN_TOTAL_CARDS:number=9;

  constructor(private router:Router, public language:LanguageService, private dataFR:DataFRService, private dataEN:DataENService, private messageBar:MatSnackBar, private dialog:MatDialog) { }

  select(card: Card){
    card.selected = !card.selected;
  }

  ready(){
    let selectedRedCards:Card[] = this.data.redCards.filter(n => n.selected);
    let selectedGreenCards:Card[] = this.data.greenCards.filter(n => n.selected);
    let selectedBlueCards:Card[] = this.data.blueCards.filter(n => n.selected);

    let conditionnalCards1:boolean = [selectedRedCards.find(i => i.id == 17), selectedGreenCards.find(i => i.id == 5), selectedBlueCards.find(i => i.id == 29)].filter(n => n!=undefined).length>1;
    let conditionnalCards2:boolean = [selectedRedCards.find(i => i.id == 20), selectedGreenCards.find(i => i.id == 8), selectedBlueCards.find(i => i.id == 32)].filter(n => n!=undefined).length>1;
    let conditionnalCards3:boolean = [selectedRedCards.find(i => i.id == 22), selectedGreenCards.find(i => i.id == 10), selectedBlueCards.find(i => i.id == 34)].filter(n => n!=undefined).length>1;
    let conditionnalCards4:boolean = [selectedRedCards.find(i => i.id == 23), selectedGreenCards.find(i => i.id == 11), selectedBlueCards.find(i => i.id == 35)].filter(n => n!=undefined).length>1;
    let conditionnalCards5:boolean = [selectedRedCards.find(i => i.id == 24), selectedGreenCards.find(i => i.id == 12), selectedBlueCards.find(i => i.id == 36)].filter(n => n!=undefined).length>1;
    let conditionnalCards6:boolean = [selectedRedCards.find(i => i.id == 15), selectedGreenCards.find(i => i.id == 3), selectedBlueCards.find(i => i.id == 27)].filter(n => n!=undefined).length>1;

    if( selectedRedCards.length==0 || selectedGreenCards.length==0 || selectedBlueCards.length==0){
      if(this.language.preferred=="FR"){
        this.messageBar.open("Faites votre choix et ne conservez que les cartes qui vous CORRESPONDENT VRAIMENT dans chaque catégorie", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else if(this.language.preferred=="EN"){
        this.messageBar.open("Make your choice and keep only the cards that REALLY MATCH you in each category", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else{
        this.router.navigate(['/', 'error']);
      }
    }else if(selectedRedCards.length+selectedGreenCards.length+selectedBlueCards.length<this.MIN_TOTAL_CARDS){
      if(this.language.preferred=="FR"){
        this.messageBar.open("Trop peu de cartes choisies", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else if(this.language.preferred=="EN"){
        this.messageBar.open("Not enough cards selected", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else{
        this.router.navigate(['/', 'error']);
      }
      /* Deactivated conditions for now
    }else if(conditionnalCards1 || conditionnalCards2 || conditionnalCards3 || conditionnalCards4 || conditionnalCards5){
      const dialogConfig = new MatDialogConfig();

      dialogConfig.position = {
        'bottom':'10px'
      };

      dialogConfig.data = {
        cc1 : conditionnalCards1,
        cc2 : conditionnalCards2,
        cc3 : conditionnalCards3,
        cc4 : conditionnalCards4,
        cc5 : conditionnalCards5,
        cc6 : conditionnalCards6
      };

      this.dialog.open(ConditionnalcardsDialogComponent,dialogConfig);
      */
    }else{
      this.data.redCards = selectedRedCards;
      this.data.greenCards = selectedGreenCards;
      this.data.blueCards = selectedBlueCards;

      for(let card of this.data.redCards){
        card.selected = false;
      }
      for(let card of this.data.greenCards){
        card.selected = false;
      }
      for(let card of this.data.blueCards){
        card.selected = false;
      }

      this.chooseType();

      this.router.navigate(['/', 'type']);
    }
  }

  chooseType(){
    let redLength:number = this.data.redCards.length;
    let greenLength:number = this.data.greenCards.length;
    let blueLength:number = this.data.blueCards.length;

    if(redLength==greenLength && redLength==blueLength){
      this.data.type=Type.CAMELEON;
    }else{

      if(redLength==greenLength){
        if(blueLength<redLength){
          this.data.type=Type.DOUBLE_RED_GREEN;
        }else{
          if(blueLength>redLength+this.STRONG_WEAK_DIFF){
            this.data.type=Type.STRONG_DOMINANT_BLUE;
          }else{
            this.data.type=Type.WEAK_DOMINANT_BLUE;
          }
        }
      }else if(greenLength==blueLength){
        if(redLength<greenLength){
          this.data.type=Type.DOUBLE_GREEN_BLUE;
        }else{
          if(redLength>greenLength+this.STRONG_WEAK_DIFF){
            this.data.type=Type.STRONG_DOMINANT_RED;
          }else{
            this.data.type=Type.WEAK_DOMINANT_RED;
          }
        }
      }else if(blueLength==redLength){
        if(greenLength<blueLength){
          this.data.type=Type.DOUBLE_BLUE_RED;
        }else{
          if(greenLength>blueLength+this.STRONG_WEAK_DIFF){
            this.data.type=Type.STRONG_DOMINANT_GREEN;
          }else{
            this.data.type=Type.WEAK_DOMINANT_GREEN;
          }
        }
      }else{
        if(redLength>greenLength && redLength>blueLength){
          if(greenLength>blueLength){
            this.data.type=Type.RED_GREEN_BLUE;
          }else{
            this.data.type=Type.RED_BLUE_GREEN;
          }
        }else if(greenLength>blueLength && greenLength>redLength){
          if(blueLength>redLength){
            this.data.type=Type.GREEN_BLUE_RED;
          }else{
            this.data.type=Type.GREEN_RED_BLUE;
          }
        }else if(blueLength>redLength && blueLength>greenLength){
          if(redLength>greenLength){
            this.data.type=Type.BLUE_RED_GREEN;
          }else{
            this.data.type=Type.BLUE_GREEN_RED;
          }
        }
      }
    }
  }

  ngOnInit(): void {
    switch(this.language.preferred){
      case(Language.FRENCH):
        this.data=this.dataFR;
        break;
      case(Language.ENGLISH):
        this.data=this.dataEN;
        break;
      default:
        this.router.navigate(['/', 'error']);
    }
  }

}