import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http'

import { JWTInterceptorService } from './services/API/jwt-interceptor.service'

import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'; 
import { MatSortModule } from '@angular/material/sort';
import { ChartsModule } from 'ng2-charts';
import { MatTableExporterModule } from 'mat-table-exporter';

import {BackButtonDisableModule} from 'angular-disable-browser-back-button';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio'; 

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './components/elements/header/header.component';
import { FooterComponent } from './components/elements/footer/footer.component';
import { StartComponent } from './components/start/start.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ErrorComponent } from './components/error/error.component';
import { RulesComponent } from './components/rules/rules.component';
import { CategoryCardComponent } from './components/cards/category-card/category-card.component';
import { BasicCardComponent } from './components/cards/basic-card/basic-card.component';
import { SortingComponent } from './components/sorting/sorting.component';
import { CategoriesComponent } from './components/elements/categories/categories.component';
import { SortingDialogComponent } from './components/dialog/sorting-dialog/sorting-dialog.component';
import { WrongCardComponent } from './components/cards/wrong-card/wrong-card.component';
import { SelectionComponent } from './components/selection/selection.component';
import { SelectedCardComponent } from './components/cards/selected-card/selected-card.component';
import { ConditionnalcardsDialogComponent } from './components/dialog/conditionnalcards-dialog/conditionnalcards-dialog.component';
import { TypeComponent } from './components/type/type.component';
import { FinalSelectionComponent } from './components/final-selection/final-selection.component';
import { ResultsComponent } from './components/results/results.component';
import { DbAccessComponent } from './admin/db-access/db-access.component';
import { LoginComponent } from './admin/login/login.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        StartComponent,
        ProfileComponent,
        ErrorComponent,
        RulesComponent,
        CategoryCardComponent,
        BasicCardComponent,
        SortingComponent,
        CategoriesComponent,
        SortingDialogComponent,
        WrongCardComponent,
        SelectionComponent,
        SelectedCardComponent,
        ConditionnalcardsDialogComponent,
        TypeComponent,
        FinalSelectionComponent,
        ResultsComponent,
        DbAccessComponent,
        LoginComponent,
        ThankyouComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        DragDropModule,
        MatButtonModule,
        MatSnackBarModule,
        MatTooltipModule,
        MatInputModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatDialogModule,
        BackButtonDisableModule.forRoot({
            preserveScrollPosition: true
        }),
        HttpClientModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatRadioModule,
        ChartsModule,
        MatTableExporterModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JWTInterceptorService,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
