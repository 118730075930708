import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DbAccessComponent } from './admin/db-access/db-access.component';
import { LoginComponent } from './admin/login/login.component';
import { ErrorComponent } from './components/error/error.component';
import { FinalSelectionComponent } from './components/final-selection/final-selection.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ResultsComponent } from './components/results/results.component';
import { RulesComponent } from './components/rules/rules.component';
import { SelectionComponent } from './components/selection/selection.component';
import { SortingComponent } from './components/sorting/sorting.component';
import { StartComponent } from './components/start/start.component';
import { ThankyouComponent } from './components/thankyou/thankyou.component';
import { TypeComponent } from './components/type/type.component';

const routes: Routes = [
  {path: 'start', component:StartComponent},
  {path: 'profile', component:ProfileComponent},
  {path: 'rules', component:RulesComponent},
  {path: 'sorting', component:SortingComponent},
  {path: 'selection', component:SelectionComponent},
  {path: 'type', component:TypeComponent},
  {path: 'final-selection', component:FinalSelectionComponent},
  {path: 'results', component:ResultsComponent},
  {path: 'thankyou', component:ThankyouComponent},
  
  {path: 'login', component:LoginComponent},
  {path: 'db-access', component:DbAccessComponent},
  
  {path: 'error', component:ErrorComponent},
  {path: '**', component:ErrorComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
