import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Card } from 'src/app/models/Card';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-basic-card',
  templateUrl: './basic-card.component.html',
  styleUrls: ['./basic-card.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BasicCardComponent implements OnInit {

  @Input() card:Card;
  
  constructor(public language:LanguageService) { }

  getTipFR(card:Card) {
    return "Définition : "+card.definition+"\nRisque : "+card.risque;
  }

  getTipEN(card:Card) {
    return "Definition : "+card.definition+"\nRisk : "+card.risque;
  }

  ngOnInit(): void {
  }

}
