<div *ngIf="language.preferred=='FR'">
    <h1 class="titre" mat-dialog-title>Cartes conditionnelles</h1>
    <mat-dialog-content class="textContainer">
        <p>Attention à la cohérence de vos choix. Vous ne pouvez conserver qu’une seule option pour chacun des trios suivants:</p>
        <ul>
            <li *ngIf="cc.cc1">Attaché à l’historique / Saisit l’instant présent / Se projette dans l’avenir</li>
            <li *ngIf="cc.cc2">Compose avec la pression / Attend le dernier moment pour agir / Intègre la pression pour y échapper</li>
            <li *ngIf="cc.cc3">Evite la confrontation / Provoque la confrontation / Rationalise la confrontation</li>
            <li *ngIf="cc.cc4">Fait abstraction du cadre / Sort du cadre / Respecte le cadre</li>
            <li *ngIf="cc.cc5">Adapte son point de vue / Impose son point de vue / Défend son point de vue</li>
            <li *ngIf="cc.cc6">Stimulé par le groupe / Stimulé par le défi / Stimulé par un plan maîtrisé</li>
        </ul>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button mat-dialog-close>Fermer</button>
    </mat-dialog-actions>
</div>
<div *ngIf="language.preferred=='EN'">
    <h1 class="titre" mat-dialog-title>Conditionnal cards</h1>
    <mat-dialog-content class="textContainer">
        <p>Be sure to be consistent in your choices. You can only retain one option for each of the following trios:</p>
        <ul>
            <li *ngIf="cc.cc1">Committed to the past / Seizes the moment / Plans the future</li>
            <li *ngIf="cc.cc2">Accept to work under pressure / Awaits until the last minute to take action / Deals with pressure to avoid it</li>
            <li *ngIf="cc.cc3">Avoids confrontation / Seeks confrontation / Rationalizes confrontation</li>
            <li *ngIf="cc.cc4">Disregards the framework / Breaks the framework / Respects the framework</li>
            <li *ngIf="cc.cc5">Adjust her point of view / Imposes her point of view / Defends her point of view</li>
            <li *ngIf="cc.cc6">Stimulated by the group / Stimulated by challenges / Boosted by a well-defined plan</li>
        </ul>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
</div>
