import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ProfileService } from '../../services/data/profile.service';
import { Profile } from '../../models/Profile';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  MAX_CHAR_LENGTH:number = 50;

  profileForm:UntypedFormGroup;

  constructor(private router:Router, public language:LanguageService, private formBuilder:UntypedFormBuilder, private profile:ProfileService) { }

  ngOnInit(): void {
    this.profileForm = this.formBuilder.group({
      gender:[null,[Validators.required]],
      name:[null,[Validators.required, Validators.maxLength(this.MAX_CHAR_LENGTH)]],
      surname:[null,[Validators.required, Validators.maxLength(this.MAX_CHAR_LENGTH)]],
      email:[null,[Validators.required, Validators.email]],
      phone:[null,[Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')]],
      compagny:[null,[Validators.maxLength(this.MAX_CHAR_LENGTH)]],
      code:[null,[Validators.pattern('^[a-zA-Z0-9\d]{1,20}$')]]
    })
    this.profileForm.get('gender').setValue("Femme")
  }

  submit(){
    let profile:Profile = {
      gender: this.profileForm.get('gender').value,
      name: this.profileForm.get('name').value,
      surname: this.profileForm.get('surname').value,
      email: this.profileForm.get('email').value,
      phone: this.profileForm.get('phone').value,
      compagny: this.profileForm.get('compagny').value,
      code: this.profileForm.get('code').value,
    }
    this.profile.currentProfile = profile;
    this.router.navigate(['/','rules']);
  }

}