import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataENService } from 'src/app/services/data/data-en.service';
import { DataFRService } from 'src/app/services/data/data-fr.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.css']
})
export class ThankyouComponent implements OnInit {

  constructor(private router:Router, public language:LanguageService, private dataFR:DataFRService, private dataEN:DataENService) { }

  ngOnInit(): void {
    setTimeout(() => {
      window.location.reload()
    }, 10000);
  }

}