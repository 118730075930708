<app-categories></app-categories>

<div class="cardContainer">
    <div class="categoryRow">
      <div class="cardCategory">
        <div class="cardBox" *ngFor="let card of data.greenCards" (click)="select(card)">
          <app-basic-card *ngIf="!card.selected" [card]="card"></app-basic-card>
          <app-selected-card *ngIf="card.selected" [card]="card"></app-selected-card>
        </div>
      </div>
      <div class="cardCategory">
          <div class="cardBox" *ngFor="let card of data.redCards" (click)="select(card)">
            <app-basic-card *ngIf="!card.selected" [card]="card"></app-basic-card>
            <app-selected-card *ngIf="card.selected" [card]="card"></app-selected-card>
        </div>
      </div>
      <div class="cardCategory">
          <div class="cardBox" *ngFor="let card of data.blueCards" (click)="select(card)">
            <app-basic-card *ngIf="!card.selected" [card]="card"></app-basic-card>
            <app-selected-card *ngIf="card.selected" [card]="card"></app-selected-card>
        </div>
      </div>
    </div>
    <div class="interfaceRow">
      <div class="deck">
        <div class="textContainer" *ngIf="language.preferred=='FR'">
          <div>Choisissez {{nCards}} en cliquant dessus. Puis continuez</div>
        </div>
        <div class="textContainer" *ngIf="language.preferred=='EN'">
          <div>Select {{nCards}} by clicking on them. Then continue</div>>
        </div>
      </div>
      <div class="buttonContainer" *ngIf="language.preferred=='FR'">
          <button class="button" mat-raised-button (click)="ready()">Je continue</button>
      </div>
      <div class="buttonContainer" *ngIf="language.preferred=='EN'">
          <button class="button" mat-raised-button (click)="ready()">Continue</button>
      </div>
    </div>
</div> 