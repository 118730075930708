<mat-form-field class="searchbar">
    <mat-label>Rechercher par ID ou par code atelier</mat-label>
    <input matInput (keyup)="applyFilter($event)">
  </mat-form-field>

<table mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter">
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef>ID</th>
        <td mat-cell *matCellDef="let result">{{ result._id }}</td>
    </ng-container>
    <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>Code atelier</th>
        <td mat-cell *matCellDef="let result">{{ result.code }}</td>
    </ng-container>
    <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Dominante comportementale</th>
        <td mat-cell *matCellDef="let result">{{ findNameByID(data.typeList,result.type) }}</td>
    </ng-container>
    <ng-container matColumnDef="redCards">
        <th mat-header-cell *matHeaderCellDef>Cartes rouges</th>
        <td mat-cell *matCellDef="let result">{{ findCardsByID(data.redCardsList,result.redCards) }}</td>
    </ng-container>
    <ng-container matColumnDef="greenCards">
        <th mat-header-cell *matHeaderCellDef>Cartes vertes</th>
        <td mat-cell *matCellDef="let result">{{ findCardsByID(data.greenCardsList,result.greenCards) }}</td>
    </ng-container>
    <ng-container matColumnDef="blueCards">
        <th mat-header-cell *matHeaderCellDef>Cartes bleues</th>
        <td mat-cell *matCellDef="let result">{{ findCardsByID(data.blueCardsList,result.blueCards) }}</td>
    </ng-container>
    <ng-container matColumnDef="remove">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let result">
            <span>
                <button mat-raised-button type="button" (click)="removeResult(result)">Supprimer</button>
            </span>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>

<div class="table-footer">
    <button mat-raised-button (click)="exporter.exportTable('xlsx')">Télécharger les résultats au format Excel</button>
    <mat-paginator [length]="resultsLength" [pageSize]="pageSize"></mat-paginator>
</div>

<div class="graphics">
    <div class="pie">
        <canvas baseChart [data]="pieData" [labels]="pieLabels" [chartType]="pieChartType" [colors]="pieColors"
                [options]="pieOptions" [plugins]="piePlugins" [legend]="pieLegend"></canvas>
    </div>
    <div class="radar">
        <canvas baseChart [datasets]="radarData" [options]="radarOptions" [labels]="radarLabels" [chartType]="radarChartType"></canvas>
    </div>
    <div class="cards">
        <div class="bar">
            <canvas baseChart [datasets]="greenBarData" [labels]="greenBarLabels" [options]="greenBarOptions"
                    [plugins]="barPlugins" [legend]="barLegend" [chartType]="barChartType"></canvas>
        </div>
        <div class="bar">
            <canvas baseChart [datasets]="redBarData" [labels]="redBarLabels" [options]="redBarOptions"
                    [plugins]="barPlugins" [legend]="barLegend" [chartType]="barChartType"></canvas>
        </div>
        <div class="bar">
            <canvas baseChart [datasets]="blueBarData" [labels]="blueBarLabels" [options]="blueBarOptions"
                    [plugins]="barPlugins" [legend]="barLegend" [chartType]="barChartType"></canvas>
        </div>
    </div>
</div>