import { Component, OnInit, Input } from '@angular/core';
import { Category } from 'src/app/models/Category';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-category-card',
  templateUrl: './category-card.component.html',
  styleUrls: ['./category-card.component.css'],
  animations: [
    trigger('flip', [
      state('unflipped', style({
      })),
      state('flipped', style({
      })),
      transition('unflipped <=> flipped', [
        animate('600ms ease-in-out', style({
          transform: 'rotateY(180deg)'
        }))
      ])
    ])
  ]
})
export class CategoryCardComponent implements OnInit {

  @Input() category:Category;

  flipped:boolean = false;
  hidden:boolean = false;

  flip(){
    this.flipped =! this.flipped;
  }

  flipStart(){
    this.hidden = true;
  }

  flipStop(){
    this.hidden = false;
  }

  constructor() { }

  ngOnInit(): void {
  }

}
