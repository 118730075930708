import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-sorting-dialog',
  templateUrl: './sorting-dialog.component.html',
  styleUrls: ['./sorting-dialog.component.css']
})
export class SortingDialogComponent implements OnInit {

  constructor(public language:LanguageService) { }

  ngOnInit(): void {
  }

}
