<app-header></app-header>

<object data="./../../../assets/HV-line.svg" type="image/svg+xml"></object>

<div class="categoryContainer">
    <div class="cardContainer" *ngFor="let category of data.categories">
        <app-category-card [category]="category"></app-category-card>
    </div>
</div>

<div *ngIf="language.preferred=='FR'">
    <div class="textContainer">
        Il s’agit de regrouper, dans les 3 catégories ci-dessus
        les cartes que vous allez découvrir en jouant.<br/>
        Cliquez sur chacune d'entre elles pour lire leur définition
    </div>
    
    <div class="buttonContainer">
        <button class="button" mat-raised-button (click)="play()">Je joue</button>
    </div>    
</div>

<div *ngIf="language.preferred=='EN'">
    <div class="textContainer">
        Please group the cards that you will soon uncover while playing
        into the above 3 categories.<br/>But first, click on each of them
        to read their definition
    </div>
    
    <div class="buttonContainer">
        <button class="button" mat-raised-button (click)="play()">Let’s play</button>
    </div> 
</div>

<app-footer class="footer"></app-footer>