import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-conditionnalcards-dialog',
  templateUrl: './conditionnalcards-dialog.component.html',
  styleUrls: ['./conditionnalcards-dialog.component.css']
})
export class ConditionnalcardsDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public cc:any, public language:LanguageService) { }

  ngOnInit(): void {
  }

}
