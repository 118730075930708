import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { DataFRService } from 'src/app/services/data/data-fr.service';
import { DataENService } from 'src/app/services/data/data-en.service';
import { Language } from 'src/app/models/Language';
import { ApiService } from 'src/app/services/API/api.service';
import { ProfileService } from '../../services/data/profile.service';
import { ExtendedResult } from 'src/app/models/ExtendedResult';
@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {

  public data;

  currentResult:ExtendedResult;

  public dominant:string
  public comment:string;
  public croyances:string;
  public forces:string;
  public risques:string
  public peurs:string;

  constructor(private router:Router, public language:LanguageService, private dataFR:DataFRService, private dataEN:DataENService, private API:ApiService, private profile:ProfileService) { }

  extractID(array:any,key:string){
    return array.map(function(item) {return item[key]})
  }

  submit(){
    console.log(this.currentResult)
    console.log(this.profile.currentProfile)
    console.log(this.language.preferred)
    this.API.sendSave({result: this.currentResult, profile: this.profile.currentProfile, language:this.language.preferred}).subscribe(
      (res) => {},
      (error) => {
        console.log(error);
      }
    );
  }

  with(){
    this.API.sendContact({result: this.currentResult, profile: this.profile.currentProfile, language:this.language.preferred}).subscribe(
      (res) => {
        this.router.navigate(['/', 'thankyou']);
      },
      (error) => {
        console.log(error);
    });
  }

  without(){
    this.router.navigate(['/', 'start']);
  }

  ngOnInit(): void {
    switch(this.language.preferred){
      case(Language.FRENCH):
        this.data=this.dataFR;
        break;
      case(Language.ENGLISH):
        this.data=this.dataEN;
        break;
      default:
        this.router.navigate(['/', 'error']);
    }

    this.currentResult = {
      type:(this.data.typeList.find(element => element.id == this.data.type)),
      redCards:this.data.redCards,
      greenCards:this.data.greenCards,
      blueCards:this.data.blueCards
    };

    console.log(this.currentResult)

    this.dominant = this.currentResult.type.dominant;
    this.comment = this.currentResult.type.comment;
    this.croyances = this.currentResult.type.croyances;
    this.forces = this.currentResult.type.forces;
    this.risques = this.currentResult.type.risques;
    this.peurs = this.currentResult.type.peurs;

    this.submit();
  }

}
