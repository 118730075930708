<app-header></app-header>

<object data="./../../../assets/HV-line.svg" type="image/svg+xml"></object>

<div *ngIf="language.preferred=='FR'">
    <p class="titre">Une erreur s'est produite ! Informez le responsable du site s'il vous plait.</p>
    <p class="titre">Vous serez redirigé automatiquement dans quelques secondes.</p>
</div>

<div *ngIf="language.preferred=='EN'">
    <p class="titre">An error occurred ! Please inform the administrator of the site.</p>
    <p class="titre">You will be redirected automatically in a few seconds</p>
</div>

<app-footer></app-footer>