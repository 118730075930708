<app-header></app-header>

<object data="./../../../assets/HV-line.svg" type="image/svg+xml"></object>

<div *ngIf="language.preferred=='FR'">
    <div class="textContainer">
        Afin que nous puissions vous faire parvenir votre profil comportemental,
        veuillez compléter les coordonnées de contact ci-dessous.
    </div>
        
    <div class="formContainer">
        <form [formGroup]="profileForm" (submit)="submit()">
            <div class="radioZone">
                <div class="radioButton">
                    <mat-label>Mme.</mat-label>
                    <input type="radio" formControlName="gender" value="Femme">
                </div>
                <div class="radioButton">
                    <mat-label>M.</mat-label>
                    <input type="radio" formControlName="gender" value="Homme">
                </div>
                <div class="radioButton">
                    <mat-label>Autre</mat-label>
                    <input type="radio" formControlName="gender" value="Autre">
                </div>
            </div>
            <mat-form-field>
                <mat-label>Nom</mat-label>
                <input matInput type="text" formControlName="name">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Prénom</mat-label>
                <input matInput type="text" formControlName="surname">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput type="email" formControlName="email">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Téléphone</mat-label>
                <input matInput type="tel" formControlName="phone">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Société</mat-label>
                <input matInput type="text" formControlName="compagny">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Code atelier</mat-label>
                <input matInput type="text" formControlName="code">
                <mat-hint align="start">Si vous avez reçu un code atelier, il est indispensable de l’indiquer ici</mat-hint>
            </mat-form-field>
            <button class="button" mat-raised-button type="submit" [disabled]="!profileForm.valid">Je me lance !</button>
        </form>
    </div>
</div>

<div *ngIf="language.preferred=='EN'">
    <div class="textContainer">
        To receive your behavioral preferences, please complete the contact information below.
    </div>
        
    <div class="formContainer">
        <form [formGroup]="profileForm" (submit)="submit()">
            <div class="radioZone">
                <div class="radioButton">
                    <mat-label>Mrs.</mat-label>
                    <input type="radio" formControlName="gender" value="Femme">
                </div>
                <div class="radioButton">
                    <mat-label>Mr.</mat-label>
                    <input type="radio" formControlName="gender" value="Homme">
                </div>
                <div class="radioButton">
                    <mat-label>Other</mat-label>
                    <input type="radio" formControlName="gender" value="Autre">
                </div>
            </div>
            <mat-form-field>
                <mat-label>Last name</mat-label>
                <input matInput type="text" formControlName="name">
            </mat-form-field>
            <mat-form-field>
                <mat-label>First name</mat-label>
                <input matInput type="text" formControlName="surname">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Email</mat-label>
                <input matInput type="email" formControlName="email">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Phone</mat-label>
                <input matInput type="tel" formControlName="phone">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Compagny</mat-label>
                <input matInput type="text" formControlName="compagny">
            </mat-form-field>
            <mat-form-field>
                <mat-label>Workshop code</mat-label>
                <input matInput type="text" formControlName="code">
                <mat-hint align="start">If available, indicate here your workshop number</mat-hint>
            </mat-form-field>
            <button class="button" mat-raised-button type="submit" [disabled]="!profileForm.valid">Let’s get started !</button>
        </form>
    </div>
</div>

<app-footer></app-footer>