<app-categories></app-categories>

<div class="cardContainer" cdkDropListGroup>
  <div class="categoryRow">
    <div class="cardCategory" cdkDropList id="greenCards" [cdkDropListData]="data.greenCards" (cdkDropListDropped)="dropInGreen($event)">
      <div class="cardBox" *ngFor="let card of data.greenCards" cdkDrag>
          <app-basic-card *ngIf="!card.wrong" [card]="card"></app-basic-card>
          <app-wrong-card *ngIf="card.wrong" [card]="card"></app-wrong-card>
      </div>
    </div>
    <div class="cardCategory" cdkDropList id="redCards" [cdkDropListData]="data.redCards" (cdkDropListDropped)="dropInRed($event)">
      <div class="cardBox" *ngFor="let card of data.redCards" cdkDrag>
        <app-basic-card *ngIf="!card.wrong" [card]="card"></app-basic-card>
        <app-wrong-card *ngIf="card.wrong" [card]="card"></app-wrong-card>
      </div>
    </div>
    <div class="cardCategory" cdkDropList id="blueCards" [cdkDropListData]="data.blueCards" (cdkDropListDropped)="dropInBlue($event)">
      <div class="cardBox" *ngFor="let card of data.blueCards" cdkDrag>
        <app-basic-card *ngIf="!card.wrong" [card]="card"></app-basic-card>
        <app-wrong-card *ngIf="card.wrong" [card]="card"></app-wrong-card>
      </div>
    </div>
  </div>
  <div class="interfaceRow">
    <div class="deck" id="deckCards" cdkDropList [cdkDropListData]="data.deckCards">
      <div class="textContainer" *ngIf="language.preferred=='FR'" [hidden]="wrongCards">
        <div *ngIf="data.deckCards.length>=1">
          Placez chacune de ces cartes dans la colonne à laquelle vous pensez qu'elle appartient
        </div>
        <div *ngIf="data.deckCards.length<=0">
          Vérifiez vos réponses en sélectionnant 'Je continue'
        </div>
      </div>
      <div class="textContainer" *ngIf="language.preferred=='EN'" [hidden]="wrongCards">
        <div *ngIf="data.deckCards.length>=1">
          Place each of these cards in the column to which you think it belongs
        </div>
        <div *ngIf="data.deckCards.length<=0">
          Check your answers by selecting 'Move on'
        </div>
      </div>
      <div class="cardBox" *ngIf="data.deckCards.length>=1" [hidden]="wrongCards" cdkDrag>
        <app-selected-card *ngIf="nextCard!=null" [card]="nextCard"></app-selected-card>
      </div>
    </div>
    <div class="buttonContainer" *ngIf="language.preferred=='FR'">
      <button class="button" mat-raised-button (click)="help()">Aide</button>
      <button class="button" mat-raised-button (click)="check()">Je continue</button>
    </div>
    <div class="buttonContainer" *ngIf="language.preferred=='EN'">
      <button class="button" mat-raised-button (click)="help()">Help</button>
      <button class="button" mat-raised-button (click)="check()">Let’s move on</button>
    </div>
  </div>
</div> 