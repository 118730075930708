import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { User } from 'src/app/models/User';
import { ApiService } from 'src/app/services/API/api.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm:UntypedFormGroup;

  MESSAGE_TIME:number = 5000;

  constructor(private formBuilder:UntypedFormBuilder, private API:ApiService, private router:Router, private messageBar:MatSnackBar) {}

  login(){
    let user:User = {
      username: this.loginForm.get("username").value,
      password: this.loginForm.get("password").value
    };

    this.API.login(user).subscribe(
      (res) => {
        sessionStorage.setItem('HVtoken',res);
        this.router.navigate(['/','db-access']);
      },
      (error) => {
        this.loginForm.reset();
        this.messageBar.open("Wrong user/password","X",{
          duration: this.MESSAGE_TIME
        })
      }
    )
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username:[null,[Validators.required]],
      password:[null,[Validators.required]]
    });
  }

}
