import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { DataFRService } from 'src/app/services/data/data-fr.service';
import { DataENService } from 'src/app/services/data/data-en.service';
import { Language } from 'src/app/models/Language';
import { Type } from 'src/app/models/Type';

@Component({
  selector: 'app-type',
  templateUrl: './type.component.html',
  styleUrls: ['./type.component.css']
})
export class TypeComponent implements OnInit {

  public data;

  type:string;

  constructor(private router:Router, public language:LanguageService, private dataFR:DataFRService, private dataEN:DataENService) { }

  ready(){
    this.router.navigate(['/', 'final-selection']);
  }

  ngOnInit(): void {
    switch(this.language.preferred){
      case(Language.FRENCH):
        this.data=this.dataFR;
        break;
      case(Language.ENGLISH):
        this.data=this.dataEN;
        break;
      default:
        this.router.navigate(['/', 'error']);
    }

    this.type = (this.data.typeList.find(element => element.id == this.data.type)).dominant;
  }
}
