import { Injectable } from '@angular/core';
import { Category } from 'src/app/models/Category';
import { Card } from 'src/app/models/Card';
import { Type } from 'src/app/models/Type';
import { Color } from 'src/app/models/Color';

import categoryList from '../../../assets/categoriesFR.json'
import greenCardsList from '../../../assets/greenDataFR.json'
import redCardsList from '../../../assets/redDataFR.json'
import blueCardsList from '../../../assets/blueDataFR.json'
import typeList from '../../../assets/typeFR.json'

@Injectable({
  providedIn: 'root'
})
export class DataFRService {

  CARDS_PER_CATEGORY:number=12;

  categories:Category[];

  redCards: Card[];
  greenCards: Card[];
  blueCards: Card[];

  deckCards:Card[];

  type:Type;

  greenCardsList = greenCardsList;
  redCardsList = redCardsList;
  blueCardsList = blueCardsList;
  typeList = typeList;
  
  constructor() {

    this.categories = [
      {
        name:categoryList[0].name,
        definition:categoryList[0].definition,
        color:Color.GREEN
      },
      {
        name:categoryList[1].name,
        definition:categoryList[1].definition,
        color:Color.RED
      },
      {
        name:categoryList[2].name,
        definition:categoryList[2].definition,
        color:Color.BLUE
      }
    ];
    
    this.redCards = [];
    this.greenCards = [];
    this.blueCards = [];

    this.deckCards = [];
    for(let i=0;i<this.CARDS_PER_CATEGORY;++i){
      this.deckCards.push(
        {
          id:redCardsList[i].id,
          name:redCardsList[i].nom,
          definition:redCardsList[i].definition,
          risque:redCardsList[i].risque,
          color:Color.RED,
          restrictedColors:[],
          wrong:false,
          selected:false
        }
      );
      this.deckCards.push(
        {
          id:greenCardsList[i].id,
          name:greenCardsList[i].nom,
          definition:greenCardsList[i].definition,
          risque:greenCardsList[i].risque,
          color:Color.GREEN,
          restrictedColors:[],
          wrong:false,
          selected:false
        }
      );
      this.deckCards.push(
        {
          id:blueCardsList[i].id,
          name:blueCardsList[i].nom,
          definition:blueCardsList[i].definition,
          risque:blueCardsList[i].risque,
          color:Color.BLUE,
          restrictedColors:[],
          wrong:false,
          selected:false
        }
      );
    }
    this.deckCards=this.shuffle(this.deckCards);

    this.type=Type.NONE;
  }

  shuffle(arr){
    var i,j,temp;
    for (i = arr.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
    }
    return arr;  
  }

}