import { Component, OnInit } from '@angular/core';
import { Card } from 'src/app/models/Card';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { DataFRService } from 'src/app/services/data/data-fr.service';
import { DataENService } from 'src/app/services/data/data-en.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkDragDrop, transferArrayItem } from '@angular/cdk/drag-drop';
import { Color } from 'src/app/models/Color';
import { Language } from 'src/app/models/Language';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SortingDialogComponent } from '../dialog/sorting-dialog/sorting-dialog.component';

@Component({
  selector: 'app-sorting',
  templateUrl: './sorting.component.html',
  styleUrls: ['./sorting.component.css']
})
export class SortingComponent implements OnInit {

  public data;

  MESSAGE_TIME:number = 5000;
  WRONG_TIME:number = 5000;

  wrongCards:boolean = false;

  nextCard:Card;

  constructor(private router:Router, public language:LanguageService,private dataFR:DataFRService, private dataEN:DataENService, private messageBar:MatSnackBar, private dialog:MatDialog) {
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer !== event.container) {
      if(event.container.data.length<this.data.CARDS_PER_CATEGORY){
        transferArrayItem(event.previousContainer.data,
                          event.container.data,
                          event.previousIndex,
                          event.currentIndex);
        if(this.data.deckCards.length!=0){
          this.nextCard = this.data.deckCards[0];
        }else{
          this.nextCard = null;
        }
      }else{
        if(this.language.preferred=="FR"){
          this.messageBar.open("Il y a déjà 12 cartes dans cette catégorie", "X",
            {
              duration: this.MESSAGE_TIME,
            }
          );
        }else if(this.language.preferred=="EN"){
          this.messageBar.open("here are already 12 cards in this category", "X",
            {
              duration: this.MESSAGE_TIME,
            }
          );
        }else{
          this.router.navigate(['/', 'error']);
        }
      }
    }
  }

  dropInRed(event: CdkDragDrop<string[]>){
    if(event.previousContainer.id=="deckCards" && this.data.deckCards[event.previousIndex].restrictedColors.find(x => x===Color.RED)
    || event.previousContainer.id=="greenCards" && this.data.greenCards[event.previousIndex].restrictedColors.find(x => x===Color.RED)
    || event.previousContainer.id=="blueCards" && this.data.blueCards[event.previousIndex].restrictedColors.find(x => x===Color.RED)){
      if(this.language.preferred=="FR"){
        this.messageBar.open("Vous avez déjà essayé ce choix! Relisez la définition de cette carte et placez la carte dans un autre groupe", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else if(this.language.preferred=="EN"){
        this.messageBar.open("You already tried this! Read the definition of this card again and place it in another group", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else{
        this.router.navigate(['/', 'error']);
      }
    }else{
      this.drop(event);
    }
  }


  dropInGreen(event: CdkDragDrop<string[]>){
    if(event.previousContainer.id=="deckCards" && this.data.deckCards[event.previousIndex].restrictedColors.find(x => x===Color.GREEN)
    || event.previousContainer.id=="redCards" && this.data.redCards[event.previousIndex].restrictedColors.find(x => x===Color.GREEN)
    || event.previousContainer.id=="blueCards" && this.data.blueCards[event.previousIndex].restrictedColors.find(x => x===Color.GREEN)){
      if(this.language.preferred=="FR"){
        this.messageBar.open("Vous avez déjà essayé ce choix! Relisez la définition de cette carte et placez la carte dans un autre groupe", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else if(this.language.preferred=="EN"){
        this.messageBar.open("You already tried this! Read the definition of this card again and place it in another group", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else{
        this.router.navigate(['/', 'error']);
      }
    }else{
      this.drop(event);
    }
  }

  dropInBlue(event: CdkDragDrop<string[]>){
    if(event.previousContainer.id=="deckCards" && this.data.deckCards[event.previousIndex].restrictedColors.find(x => x===Color.BLUE)
    || event.previousContainer.id=="greenCards" && this.data.greenCards[event.previousIndex].restrictedColors.find(x => x===Color.BLUE)
    || event.previousContainer.id=="redCards" && this.data.redCards[event.previousIndex].restrictedColors.find(x => x===Color.BLUE)){
      if(this.language.preferred=="FR"){
        this.messageBar.open("Vous avez déjà essayé ce choix! Relisez la définition de cette carte et placez la carte dans un autre groupe", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else if(this.language.preferred=="EN"){
        this.messageBar.open("You already tried this! Read the definition of this card again and place it in another group", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else{
        this.router.navigate(['/', 'error']);
      }
    }else{
      this.drop(event);
    }
  }

  check(){
    let wait = false;

    if(this.data.deckCards.length==0){
      for(let i=0;i<this.data.CARDS_PER_CATEGORY;i++){
        if(this.data.redCards[i].color!=Color.RED){
          this.data.redCards[i].restrictedColors.push(Color.RED);
          this.data.deckCards.push(this.data.redCards[i]);
          this.data.redCards[i].wrong=true;
          wait = true;
        }

        if(this.data.greenCards[i].color!=Color.GREEN){
          this.data.greenCards[i].restrictedColors.push(Color.GREEN);
          this.data.deckCards.push(this.data.greenCards[i]);
          this.data.greenCards[i].wrong=true;
          wait = true;
        }
        
        if(this.data.blueCards[i].color!=Color.BLUE){
          this.data.blueCards[i].restrictedColors.push(Color.BLUE);
          this.data.deckCards.push(this.data.blueCards[i]);
          this.data.blueCards[i].wrong=true;
          wait = true;
        }
      }

      if(wait){
        if(this.language.preferred=="FR"){
          this.messageBar.open("Les cartes bordées de violet sont mal placées", "X",
            {
              duration: this.WRONG_TIME,
            }
          );
        }else if(this.language.preferred=="EN"){
          this.messageBar.open("The cards outlined in purple are misplaced", "X",
            {
              duration: this.WRONG_TIME,
            }
          );
        }else{
          this.router.navigate(['/', 'error']);
        }
        this.wrongCards = true;
        setTimeout(()=>{
          this.ready();
        },this.WRONG_TIME);
      }else{
        this.ready();
      }
      

    }else{
      if(this.language.preferred=="FR"){
        this.messageBar.open("Pour continuer, vous devez placer toutes les cartes", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else if(this.language.preferred=="EN"){
        this.messageBar.open("To move on, you must play all the cards", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else{
        this.router.navigate(['/', 'error']);
      }
    }
  }
      

  ready(){
    for(let i=0;i<this.data.CARDS_PER_CATEGORY;i++){
      this.data.redCards[i].wrong=false;
      this.data.greenCards[i].wrong=false;
      this.data.blueCards[i].wrong=false;
    }

    this.wrongCards = false;

    this.data.redCards = this.data.redCards.filter(n => !this.data.deckCards.includes(n));
    this.data.greenCards = this.data.greenCards.filter(n => !this.data.deckCards.includes(n));
    this.data.blueCards = this.data.blueCards.filter(n => !this.data.deckCards.includes(n));

    if(this.data.deckCards.length!=0){
      if(this.language.preferred=="FR"){
        this.messageBar.open("Les cartes mal placées se retrouvent dans la main, réessayez", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else if(this.language.preferred=="EN"){
        this.messageBar.open("Misplaced cards return to the hand, please try again", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else{
        this.router.navigate(['/', 'error']);
      }
      this.nextCard = this.data.deckCards[0];
    }else{
      this.router.navigate(['/', 'selection']);
    }
  }

  help(){
    const dialogConfig = new MatDialogConfig();

    this.dialog.open(SortingDialogComponent,dialogConfig);
  }

  ngOnInit(): void {
    switch(this.language.preferred){
      case(Language.FRENCH):
        this.data=this.dataFR;
        break;
      case(Language.ENGLISH):
        this.data=this.dataEN;
        break;
      default:
        this.router.navigate(['/', 'error']);
    }

    this.nextCard = this.data.deckCards[0];
  }
}