import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Language } from './models/Language';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'angular';

  constructor(private router:Router, private Language:LanguageService) {}

  ngOnInit(): void {

    this.router.navigate(['/','start']);
  }
}
