import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataFRService } from 'src/app/services/data/data-fr.service';
import { DataENService } from 'src/app/services/data/data-en.service';
import { LanguageService } from 'src/app/services/language.service';
import { Language } from 'src/app/models/Language';

@Component({
  selector: 'app-rules',
  templateUrl: './rules.component.html',
  styleUrls: ['./rules.component.css']
})
export class RulesComponent implements OnInit {

  public data;

  constructor(private router:Router, public language:LanguageService, private dataFR:DataFRService, private dataEN:DataENService) { }

  ngOnInit(): void {
    switch(this.language.preferred){
      case(Language.FRENCH):
        this.data=this.dataFR;
        break;
      case(Language.ENGLISH):
        this.data=this.dataEN;
        break;
      default:
        this.router.navigate(['/','error'])
    }
  }

  play(){
    this.router.navigate(['/','sorting']);
  }

}
