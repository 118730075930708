import { Injectable } from '@angular/core';
import { Language } from 'src/app/models/Language';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  preferred:Language;

  constructor() { }
}
