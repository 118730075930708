export enum Type{
    NONE,

    CAMELEON,

    DOUBLE_RED_GREEN,
    DOUBLE_GREEN_BLUE,
    DOUBLE_BLUE_RED,

    STRONG_DOMINANT_RED,
    STRONG_DOMINANT_GREEN,
    STRONG_DOMINANT_BLUE,

    WEAK_DOMINANT_RED,
    WEAK_DOMINANT_GREEN,
    WEAK_DOMINANT_BLUE,

    RED_GREEN_BLUE,
    RED_BLUE_GREEN,
    GREEN_BLUE_RED,
    GREEN_RED_BLUE,
    BLUE_RED_GREEN,
    BLUE_GREEN_RED
}