<app-header></app-header>

<object data="./../../../assets/HV-line.svg" type="image/svg+xml"></object>

<div *ngIf="language.preferred=='FR'">
    <p class="titre">Merci d'avoir joué !</p>
    <p class="titre">Un mail avec vos résultats vous a été envoyé à l'adresse email indiquée.</p>
    <p class="titre">De même, un représentant de la société Human Venture va prendre contact avec vous dans les prochains jours.</p>
    <p class="titre">Vous allez être maintenant redirigé automatiquement vers la page de départ.</p>
</div>

<div *ngIf="language.preferred=='EN'">
    <p class="titre">Thank you for playing!</p>
    <p class="titre">An email with your results has been sent to your email address.</p>
    <p class="titre">Note that a Human Venture representative will contact you shortly.</p>
    <p class="titre">You will now be automatically redirected to the starting page.</p>
        
</div>

<app-footer></app-footer>
