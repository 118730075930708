import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUri:string = 'https://env-0603964.jcloud-ver-jpc.ik-server.com/api';
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  // Save result in Profilogram mail
  sendSave(data): Observable<any> {
    let url = `${this.baseUri}/save`;
    return this.http.post(url, data)
      .pipe(
        catchError(this.errorMgmt)
      )
  }

  // Send results to user and contact to profilogram
  sendContact(data): Observable<any> {
    let url = `${this.baseUri}/contact`;
    return this.http.post(url, data)
      .pipe(
        catchError(this.errorMgmt)
      )
  }

  // DISABLED (but has to be left on for db-access component)
  // Create result
  createResult(data): Observable<any> {
    let url = `${this.baseUri}/create`;
    return this.http.post(url, data)
      .pipe(
        catchError(this.errorMgmt)
      )
  }

  // Get results
  getResults() {
    return this.http.get(`${this.baseUri}/retrieve`);
  }

  // Delete result
  deleteResult(id): Observable<any> {
    let url = `${this.baseUri}/delete/${id}`;
    return this.http.delete(url, { headers: this.headers }).pipe(
      catchError(this.errorMgmt)
    )
  }

  // Login
  login(user): Observable<any> {
    let url = `${this.baseUri}/login`;
    return this.http.post(url, user).pipe(
      catchError(this.errorMgmt)
    )
  }
  // END OF DISABLED

  // Error handling 
  errorMgmt(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}