import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/API/api.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Result } from 'src/app/models/Result';
import { Router } from '@angular/router';
import { DataFRService } from 'src/app/services/data/data-fr.service';
import { ChartType, ChartOptions, RadialChartOptions, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { Color } from 'src/app/models/Color';
import { Type } from 'src/app/models/Type';

@Component({
  selector: 'app-db-access',
  templateUrl: './db-access.component.html',
  styleUrls: ['./db-access.component.css']
})
export class DbAccessComponent implements OnInit {

  constructor(private API:ApiService, private router:Router, public data:DataFRService) { }

  dataSource:MatTableDataSource<Result> = new MatTableDataSource();

  displayedColumns:string[] = ['id','code','type','redCards','greenCards','blueCards','remove'];

  resultsLength:number = 0;

  pageSize:number = 8;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public pieLabels: Label[] = ['Dominante verte', 'Dominante rouge', 'Dominante bleue'];
  public pieData = [0,0,0];
  public pieChartType:ChartType = 'pie';
  public pieColors = [{backgroundColor: [Color.GREEN, Color.RED, Color.BLUE]}];
  public pieOptions:ChartOptions = {
    responsive: true,
    title: {
      text: 'Répartition des couleurs dominantes',
      display: true
    }
  };
  public pieLegend = false;
  public piePlugins = [];

  public radarLabels: Label[] = ['Caméléon', 'Rouge-bleue', 'Verte-bleue', 'Rouge-verte', 'Fortement verte', 'Faiblement verte', 'Verte', 'Fortement rouge', 'Faiblement rouge', 'Rouge', 'Fortement bleue', 'Faiblement bleue', 'Bleue'];
  public radarOptions: RadialChartOptions = {
    responsive: true,
    legend: {
      display: false
    },
    title: {
      text: 'Répartition des dominantes comportementales',
      display: true
    },
    tooltips: {
      enabled: true,
      displayColors: false,
      callbacks: {
        label: function(toolTipItem, data) {
          return data.labels[toolTipItem.index] as Label;
        },
      }
    }
  };
  public radarData: ChartDataSets[] = [
    { data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], label: '', backgroundColor: 'rgba(255, 215, 0, 0.2)', pointBackgroundColor: 'rgba(255, 215, 0, 1)', borderColor: 'rgba(255, 215, 0, 0.8)'}
  ];
  public radarChartType: ChartType = 'radar';

  public barChartType: ChartType = 'bar';
  public barLegend = false;
  public barPlugins = [];

  public greenBarLabels: Label[] = ['Aime les rapports harmonieux', 'Besoin de consensus', 'Stimulé par le groupe', 'Recherche le lien', 'Attaché à l’historique', 'Attire la sympathie', 'Diplomate', 'Compose avec la pression', 'Jovial', 'Evite la confrontation', 'Fait abstraction du cadre', 'Adapte son point de vue'];
  public greenBarData: ChartDataSets[] = [
    { data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], label: 'Fréquence de la carte', backgroundColor: '#70C000', hoverBackgroundColor: '#70C000'}
  ];
  public greenBarOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          display: false
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    title: {
      display: true,
      text: "Répartition des cartes vertes"
    }
  };

  public redBarLabels: Label[] = ['Aime l’action', 'Besoin d’aller à l’essentiel', 'Stimulé par le défi', 'Recherche les résultats rapides', 'Saisit l’instant présent', 'Pragmatique', 'Dominant', 'Attend le dernier moment pour agir', 'Inspire l’enthousiasme', 'Provoque la confrontation', 'Sort du cadre', 'Impose son point de vue'];
  public redBarData: ChartDataSets[] = [
    { data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], label: 'Fréquence de la carte', backgroundColor: '#ff0000', hoverBackgroundColor: '#ff0000'}
  ];
  public redBarOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          display: false
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    title: {
      display: true,
      text: "Répartition des cartes rouges"
    }
  };

  public blueBarLabels: Label[] = ['Aime l’ordre', 'Besoin de convaincre', 'Stimulé par un plan maîtrisé', 'Recherche la précision', 'Se projette dans l’avenir', 'Logique', 'Réservé', 'Intègre la pression pour y échapper', 'Rassure', 'Rationalise la confrontation', 'Respecte le cadre', 'Défend son point de vue'];
  public blueBarData: ChartDataSets[] = [
    { data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0], label: 'Fréquence de la carte', backgroundColor: '#0070C0', hoverBackgroundColor: '#0070C0'}
  ];
  public blueBarOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{
        ticks: {
          display: false
        }
      }],
      yAxes: [{
        ticks: {
          beginAtZero: true
        }
      }]
    },
    title: {
      display: true,
      text: "Répartition des cartes bleues"
    }
  };

  findNameByID(array:any, ID:number){
    return array.find( element => element.id == ID).nom;
  }

  findCardsByID(array:any, ID:number[]){
    let result:string = "";
      ID.forEach( id => {
        if(result!="") { result = result.concat(", "); }
        result = result.concat(this.findNameByID(array,id));
      });
      return result;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    this.updatePie();
    this.updateRadar();
    this.updateBars();
  }

  removeResult(result:any){
    if(window.confirm("Remove this result ?")) {
      this.API.deleteResult(result._id).subscribe(
        (data) => {
        const index = this.dataSource.data.indexOf(result);
        this.dataSource.data.splice(index, 1);
        this.dataSource._updateChangeSubscription();
        },
        (error) => {
          this.router.navigate(['/','login']);
        });
    }
  }

  ngOnInit(): void {
    this.API.getResults().subscribe(
      (data) => {
      this.dataSource.data = (data as Result[]);
      this.updatePie();
      this.updateRadar();
      this.updateBars();
      },
      (error) => {
        this.router.navigate(['/','login']);
      });

    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  updatePie(){
    let newPieData = [0,0,0];
    this.dataSource.filteredData.forEach( result => {
      switch(result.type){
        case(Type.CAMELEON):
          newPieData[0]+=1;
          newPieData[1]+=1;
          newPieData[2]+=1;
          break;
        case(Type.DOUBLE_BLUE_RED):
          newPieData[1]+=1;
          newPieData[2]+=1;
          break;
        case(Type.DOUBLE_GREEN_BLUE):
          newPieData[0]+=1;
          newPieData[2]+=1;
          break;
        case(Type.DOUBLE_RED_GREEN):
          newPieData[0]+=1;
          newPieData[1]+=1;
          break;
        case(Type.STRONG_DOMINANT_GREEN):
        case(Type.WEAK_DOMINANT_GREEN):
        case(Type.GREEN_BLUE_RED):
        case(Type.GREEN_RED_BLUE):
          newPieData[0]+=1;
          break;
        case(Type.STRONG_DOMINANT_RED):
        case(Type.WEAK_DOMINANT_RED):
        case(Type.RED_BLUE_GREEN):
        case(Type.RED_GREEN_BLUE):
          newPieData[1]+=1;
          break;
        case(Type.STRONG_DOMINANT_BLUE):
        case(Type.WEAK_DOMINANT_BLUE):
        case(Type.BLUE_GREEN_RED):
        case(Type.BLUE_RED_GREEN):
          newPieData[2]+=1;
          break;
      }

      this.pieData = newPieData;
    });
  }

  updateRadar(){
    let newRadarData = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];

    this.dataSource.filteredData.forEach( result => {
      newRadarData[result.type-1] += 1; // -1 because of NONE type that will not be present
    });

    this.radarData[0].data = newRadarData;
  }

  updateBars(){
    let newGreenBarData = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    let newRedBarData = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
    let newBlueBarData = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];

    this.dataSource.filteredData.forEach( result => {
      result.greenCards.forEach( card => {
        newGreenBarData[card-0*this.data.CARDS_PER_CATEGORY-1] += 1; // -1 because cards start at one
      });
      result.redCards.forEach( card => {
        newRedBarData[card-1*this.data.CARDS_PER_CATEGORY-1] += 1; // -1 because cards start at one
      });
      result.blueCards.forEach( card => {
        newBlueBarData[card-2*this.data.CARDS_PER_CATEGORY-1] += 1; // -1 because cards start at one
      });
    });

    this.greenBarData[0].data = newGreenBarData;
    this.redBarData[0].data = newRedBarData;
    this.blueBarData[0].data = newBlueBarData;
  }

}
