<div *ngIf="language.preferred=='FR'">
    <h1 class="titre" mat-dialog-title>Aide</h1>
    <mat-dialog-content class="textContainer">
        <p>Chacune des catégories contient 12 cartes dont les définitions s’affichent au passage de la souris.</p>
        <p>Vous pouvez les déplacer d’une catégorie à l’autre jusqu’à ce que vous soyez satisfait.</p>
        <p>Cliquez sur le bouton «Je continue» pour vérifier la justesse de vos réponses.</p>
        <p>Redistribuez les cartes qui reviennent dans la pile. Une fois toutes les cartes bien placées, vous passerez à l’étape suivante.</p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button mat-dialog-close>Fermer</button>
    </mat-dialog-actions>
</div>
<div *ngIf="language.preferred=='EN'">
    <h1 class="titre" mat-dialog-title>Help</h1>
    <mat-dialog-content class="textContainer">
        <p>Each category contains 12 cards whose definitions are displayed on mouse-over.</p>
        <p>You can move them from one category to another until you are done.</p>
        <p>Click the "Continue" button to check your answers.</p>
        <p>Reshuffle the cards that come back into the pile. Once all the cards have been placed correctly, you can proceed to the next step.</p>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-button mat-dialog-close>Close</button>
    </mat-dialog-actions>
</div>