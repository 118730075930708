<app-categories></app-categories>

<div class="cardContainer">
    <div class="categoryRow">
      <div class="cardCategory">
        <div class="cardBox" *ngFor="let card of data.greenCards">
          <app-basic-card [card]="card"></app-basic-card>
        </div>
      </div>
      <div class="cardCategory">
          <div class="cardBox" *ngFor="let card of data.redCards">
            <app-basic-card [card]="card"></app-basic-card>
        </div>
      </div>
      <div class="cardCategory">
          <div class="cardBox" *ngFor="let card of data.blueCards">
            <app-basic-card [card]="card"></app-basic-card>
        </div>
      </div>
    </div>
    <div class="interfaceRow">
      <div class="deck">
        <div class="textContainer" *ngIf="language.preferred=='FR'">
          <div>Vous avez une dominance comportementale {{dominant | lowercase}} et avez retenu les cartes ci-dessus (vous pouvez accéder à la définition et au risque associé en passant le curseur sur la carte).</div>
          <br>
          <div>Quelle est, dans cette liste, la carte la plus importante pour vous ? On l’appelle « carte maîtresse » car elle est celle qui influence le plus votre comportement sous stress aujourd’hui. </div>
          <br>
          <div>Pour compléter l’analyse, voici quelques traits comportementaux caractéristiques:</div>
          <div *ngIf="comment.length!=0">{{comment}}</div>
          <ul>
            <li>Croyances : {{croyances}}</li>
            <li>Forces : {{forces}}</li>
            <li>Risques sous stress : {{risques}}</li>
            <li>Peur(s) première(s) : {{peurs}}</li>
          </ul>
        </div>
        <div class="textContainer" *ngIf="language.preferred=='EN'">
          <div>You have a {{dominant | lowercase}} dominance and have selected the above cards (you can access the definition and the associated risk by moving your cursor over the card).</div>
          <br>
          <div>Which of these cards is the most important to you? It is called the "master card" as it is the one that most influences your behavior under stress today.</div>
          <br>
          <div>To complete the analysis, here are some characteristic behavioral traits:</div>
          <div *ngIf="comment.length!=0">{{comment}}</div>
          <ul>
            <li>Beliefs : {{croyances}}</li>
            <li>Strengths : {{forces}}</li>
            <li>Risks when stressed : {{risques}}</li>
            <li>Basic fears : {{peurs}}</li>
          </ul>
        </div>
      </div>
      <div class="buttonContainer" *ngIf="language.preferred=='FR'">
          <button class="button" mat-raised-button (click)="without()">Recommencer</button>
          <button class="button" mat-raised-button (click)="with()">Obtenir une analyse personnalisée</button>
      </div>
      <div class="buttonContainer" *ngIf="language.preferred=='EN'">
          <button class="button" mat-raised-button (click)="without()">Try again</button>
          <button class="button" mat-raised-button (click)="with()">Get a personalized analysis</button>
      </div>
    </div>
</div> 