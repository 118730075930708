import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { DataFRService } from 'src/app/services/data/data-fr.service';
import { DataENService } from 'src/app/services/data/data-en.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Card } from 'src/app/models/Card';
import { Language } from 'src/app/models/Language';
import { Type } from 'src/app/models/Type';

@Component({
  selector: 'app-final-selection',
  templateUrl: './final-selection.component.html',
  styleUrls: ['./final-selection.component.css']
})
export class FinalSelectionComponent implements OnInit {

  public data;

  nCards:string;

  nRedCards:number;
  nGreenCards:number;
  nBlueCards:number;

  MESSAGE_TIME:number = 5000;

  constructor(private router:Router, public language:LanguageService, private dataFR:DataFRService, private dataEN:DataENService, private messageBar:MatSnackBar) { }

  select(card: Card){
    card.selected = !card.selected;
  }

  ready(){
    let selectedRedCards:Card[] = this.data.redCards.filter(n => n.selected);
    let selectedGreenCards:Card[] = this.data.greenCards.filter(n => n.selected);
    let selectedBlueCards:Card[] = this.data.blueCards.filter(n => n.selected);

    if( selectedRedCards.length!=this.nRedCards || selectedGreenCards.length!=this.nGreenCards || selectedBlueCards.length!=this.nBlueCards){
      if(this.language.preferred=="FR"){
        this.messageBar.open("Oups ! Relisez la consigne avec attention et réessayez","X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else if(this.language.preferred=="EN"){
        this.messageBar.open("Oops ! Read the instruction carefully and try again", "X",
          {
            duration: this.MESSAGE_TIME,
          }
        );
      }else{
        this.router.navigate(['/', 'error']);
      }
    }else{
      this.data.redCards = selectedRedCards;
      this.data.greenCards = selectedGreenCards;
      this.data.blueCards = selectedBlueCards;

      for(let card of this.data.redCards){
        card.selected = false;
      }
      for(let card of this.data.greenCards){
        card.selected = false;
      }
      for(let card of this.data.blueCards){
        card.selected = false;
      }

      this.router.navigate(['/', 'results']);
    }
  }

  assignNumberCards(){

    switch(this.language.preferred){
      case(Language.FRENCH):{
        switch(this.data.type){
          case(Type.CAMELEON):
            this.nCards="deux cartes par couleur";
            this.nRedCards=2;
            this.nGreenCards=2;
            this.nBlueCards=2;
            break;
          case(Type.DOUBLE_BLUE_RED):
            this.nCards="deux cartes dans chacune de vos deux couleurs primaires (bleue et rouge) et une dans votre couleur secondaire (vert)";
            this.nRedCards=2;
            this.nGreenCards=1;
            this.nBlueCards=2;
            break;
          case(Type.DOUBLE_GREEN_BLUE):
            this.nCards="deux cartes dans chacune de vos deux couleurs primaires (verte et bleue) et une dans votre couleur secondaire (rouge)";
            this.nRedCards=1;
            this.nGreenCards=2;
            this.nBlueCards=2;
            break;
          case(Type.DOUBLE_RED_GREEN):
            this.nCards="deux cartes dans chacune vos deux couleurs primaires (rouge et vert) et une dans votre couleur secondaire (bleu)";
            this.nRedCards=2;
            this.nGreenCards=2;
            this.nBlueCards=1;
            break;
          case(Type.STRONG_DOMINANT_BLUE):
            this.nCards="trois cartes dans votre couleur primaire (bleu) et une dans vos deux couleurs secondaires (rouge et vert)";
            this.nRedCards=1;
            this.nGreenCards=1;
            this.nBlueCards=3;
            break;
          case(Type.STRONG_DOMINANT_GREEN):
            this.nCards="trois cartes dans votre couleur primaire (vert) et une dans vos deux couleurs secondaires (bleu et rouge)";
            this.nRedCards=1;
            this.nGreenCards=3;
            this.nBlueCards=1;
            break;
          case(Type.STRONG_DOMINANT_RED):
            this.nCards="trois cartes dans votre couleur primaire (rouge) et une dans vos deux couleurs secondaires (vert et bleu)";
            this.nRedCards=3;
            this.nGreenCards=1;
            this.nBlueCards=1;
            break;
          case(Type.WEAK_DOMINANT_BLUE):
            this.nCards="trois cartes dans votre couleur primaire (bleu) et deux cartes dans vos deux couleurs secondaires (rouge et vert)";
            this.nRedCards=2;
            this.nGreenCards=2;
            this.nBlueCards=3;
            break;
          case(Type.WEAK_DOMINANT_GREEN):
            this.nCards="trois cartes dans votre couleur primaire (vert) et deux cartes dans vos deux couleurs secondaires (bleu et rouge)";
            this.nRedCards=2;
            this.nGreenCards=3;
            this.nBlueCards=2;
            break;
          case(Type.WEAK_DOMINANT_RED):
            this.nCards="trois cartes dans votre couleur primaire (rouge) et deux cartes dans vos deux couleurs secondaires (vert et bleu)";
            this.nRedCards=3;
            this.nGreenCards=2;
            this.nBlueCards=2;
            break;
          case(Type.BLUE_GREEN_RED):
            this.nCards="trois cartes dans votre couleur primaire (bleu), deux dans votre couleur secondaire (vert) et une dans votre couleur tertiaire (rouge)";
            this.nRedCards=1;
            this.nGreenCards=2;
            this.nBlueCards=3;
            break;
          case(Type.BLUE_RED_GREEN):
            this.nCards="trois cartes dans votre couleur primaire (bleu), deux dans votre couleur secondaire (rouge) et une dans votre couleur tertiaire (vert)";
            this.nRedCards=2;
            this.nGreenCards=1;
            this.nBlueCards=3;
            break;
          case(Type.GREEN_BLUE_RED):
            this.nCards="trois cartes dans votre couleur primaire (vert), deux dans votre couleur secondaire (bleu) et une dans votre couleur tertiaire (rouge)";
            this.nRedCards=1;
            this.nGreenCards=3;
            this.nBlueCards=2;
            break;
          case(Type.GREEN_RED_BLUE):
            this.nCards="trois cartes dans votre couleur primaire (vert), deux dans votre couleur secondaire (rouge) et une dans votre couleur tertiaire (bleu)";
            this.nRedCards=2;
            this.nGreenCards=3;
            this.nBlueCards=1;
            break;
          case(Type.RED_BLUE_GREEN):
            this.nCards="trois cartes dans votre couleur primaire (rouge), deux dans votre couleur secondaire (bleu) et une dans votre couleur tertiaire (vert)";
            this.nRedCards=3;
            this.nGreenCards=1;
            this.nBlueCards=2;
            break;
          case(Type.RED_GREEN_BLUE):
            this.nCards="trois cartes dans votre couleur primaire (rouge), deux dans votre couleur secondaire (vert) et une dans votre couleur tertiaire (bleu)";
            this.nRedCards=3;
            this.nGreenCards=2;
            this.nBlueCards=1;
            break;
          default:
            this.router.navigate(['/', 'error']);
            break;
        }
        break;
      }
      case(Language.ENGLISH):{
        switch(this.data.type){
          case(Type.CAMELEON):
            this.nCards="two cards in each color";
            this.nRedCards=2;
            this.nGreenCards=2;
            this.nBlueCards=2;
            break;
          case(Type.DOUBLE_BLUE_RED):
            this.nCards="two cards in each of your primary colors (blue and red) and one in your secondary color (green)";
            this.nRedCards=2;
            this.nGreenCards=1;
            this.nBlueCards=2;
            break;
          case(Type.DOUBLE_GREEN_BLUE):
            this.nCards="two cards in each of your primary colors (green and blue) and one in your secondary color (red)";
            this.nRedCards=1;
            this.nGreenCards=2;
            this.nBlueCards=2;
            break;
          case(Type.DOUBLE_RED_GREEN):
            this.nCards="two cards in each of your primary colors (red and green) and one in your secondary color (blue)";
            this.nRedCards=2;
            this.nGreenCards=2;
            this.nBlueCards=1;
            break;
          case(Type.STRONG_DOMINANT_BLUE):
            this.nCards="three cards in your primary color (blue) and one in each of your secondary colors (red and green)";
            this.nRedCards=1;
            this.nGreenCards=1;
            this.nBlueCards=3;
            break;
          case(Type.STRONG_DOMINANT_GREEN):
            this.nCards="three cards in your primary color (green) and one in each of your secondary colors (blue and red)";
            this.nRedCards=1;
            this.nGreenCards=3;
            this.nBlueCards=1;
            break;
          case(Type.STRONG_DOMINANT_RED):
            this.nCards="three cards in your primary color (red) and one in each of your secondary colors (green and blue)";
            this.nRedCards=3;
            this.nGreenCards=1;
            this.nBlueCards=1;
            break;
          case(Type.WEAK_DOMINANT_BLUE):
            this.nCards="three cards in your primary color (blue) and two cards in each of your secondary colors (red and green)";
            this.nRedCards=2;
            this.nGreenCards=2;
            this.nBlueCards=3;
            break;
          case(Type.WEAK_DOMINANT_GREEN):
            this.nCards="three cards in your primary color (green) and two cards in each of your secondary colors (blue and red)";
            this.nRedCards=2;
            this.nGreenCards=3;
            this.nBlueCards=2;
            break;
          case(Type.WEAK_DOMINANT_RED):
            this.nCards="three cards in your primary color (red) and two cards in each of your secondary colors (green and blue)";
            this.nRedCards=3;
            this.nGreenCards=2;
            this.nBlueCards=2;
            break;
          case(Type.BLUE_GREEN_RED):
            this.nCards="three cards in your primary color (blue), two cards in your second color (green) and one card in your tertiary color (red)";
            this.nRedCards=1;
            this.nGreenCards=2;
            this.nBlueCards=3;
            break;
          case(Type.BLUE_RED_GREEN):
            this.nCards="three cards in your primary color (blue), two cards in your second color (red) and one card in your tertiary color (green)";
            this.nRedCards=2;
            this.nGreenCards=1;
            this.nBlueCards=3;
            break;
          case(Type.GREEN_BLUE_RED):
            this.nCards="three cards in your primary color (green), two cards in your second color (blue) and one card in your tertiary color (red)";
            this.nRedCards=1;
            this.nGreenCards=3;
            this.nBlueCards=2;
            break;
          case(Type.GREEN_RED_BLUE):
            this.nCards="three cards in your primary color (green), two cards in your second color (red) and one card in your tertiary color (blue)";
            this.nRedCards=2;
            this.nGreenCards=3;
            this.nBlueCards=1;
            break;
          case(Type.RED_BLUE_GREEN):
            this.nCards="three cards in your primary color (red), two cards in your second color (blue) and one card in your tertiary color (green)";
            this.nRedCards=3;
            this.nGreenCards=1;
            this.nBlueCards=2;
            break;
          case(Type.RED_GREEN_BLUE):
            this.nCards="three cards in your primary color (red), two cards in your second color (green) and one card in your tertiary color (blue)";
            this.nRedCards=3;
            this.nGreenCards=2;
            this.nBlueCards=1;
            break;
          default:
            this.router.navigate(['/', 'error']);
            break;
        }
        break;
      }
      default:{
        this.router.navigate(['/', 'error']);
      }
    }
  }

  ngOnInit(): void {
    switch(this.language.preferred){
      case(Language.FRENCH):
        this.data=this.dataFR;
        break;
      case(Language.ENGLISH):
        this.data=this.dataEN;
        break;
      default:
        this.router.navigate(['/', 'error']);
    }

    this.assignNumberCards();
  }

}