<!--<span><button class="invisible" (click)="login()">Gestion de la base de données</button> DISABLED </span>-->

<app-header></app-header>

<object data="./../../../assets/HV-line.svg" type="image/svg+xml"></object>

<div class="buttonContainer">
    <button class="button" mat-raised-button (click)="startFR()">Découvrez vos traits comportementaux</button>
    <button class="button" mat-raised-button (click)="startEN()">Discover your behavioral traits</button>
</div>

<app-footer></app-footer>