import { Injectable } from '@angular/core';
import { Profile } from '../../models/Profile';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  currentProfile:Profile;
  
  constructor() { }
}
