<app-header></app-header>

<object data="./../../../assets/HV-line.svg" type="image/svg+xml"></object>

<div class="categoryContainer">
    <div class="cardContainer" *ngFor="let category of data.categories">
        <app-category-card [category]="category"></app-category-card>
    </div>
</div>

<div *ngIf="language.preferred=='FR'">
    <div class="textContainer">
        <p>Vous êtes presque au bout !</p>
        <p>A ce stade, votre dominance comportementale est <span style="font-weight: bold;">{{type | lowercase}}</span></p>
    </div>
    
    <div class="buttonContainer">
        <button class="button" mat-raised-button (click)="ready()">Je continue</button>
    </div>    
</div>

<div *ngIf="language.preferred=='EN'">
    <div class="textContainer">
        <p>You are almost there !</p>
        <p>At this point, your dominant behavioral pattern is  <span style="font-weight: bold;">{{type | lowercase}}</span></p>
    </div>
    
    <div class="buttonContainer">
        <button class="button" mat-raised-button (click)="ready()">Continue</button>
    </div> 
</div>

<app-footer class="footer"></app-footer>