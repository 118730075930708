<div *ngIf="language.preferred=='FR'" class="card"
[matTooltip]="getTipFR(card)"
matTooltipClass="tooltip">
    <div class="cardName">{{card.name}}</div>
</div>

<div *ngIf="language.preferred=='EN'" class="card"
[matTooltip]="getTipEN(card)"
matTooltipClass="tooltip">
    <div class="cardName">{{card.name}}</div>
</div>