<div class="container">

    <div class="categoryContainer" *ngIf="language.preferred=='FR'">
        <div class="category" *ngFor="let category of data.categories"
        [ngStyle]="{'background-color': category.color}"
        matTooltip="Définition : {{category.definition}}" matTooltipClass="tooltip">
            {{category.name}}
        </div>
    </div>

    <div class="categoryContainer" *ngIf="language.preferred=='EN'">
        <div class="category" *ngFor="let category of data.categories"
        [ngStyle]="{'background-color': category.color}"
        matTooltip="Definition : {{category.definition}}" matTooltipClass="tooltip">
            {{category.name}}
        </div>
    </div>

</div>